import axios from 'axios';

const baseAPI = "https://parseapi.back4app.com/classes/user"

let submit = document.getElementById("Submit");
submit.addEventListener('click', download);

const queryString = window.location.search;
console.log(queryString);

const urlParams = new URLSearchParams(queryString);

var url = urlParams.get('url')
console.log(url)
if(url == "" || url == null) {
    url = "https://ik.imagekit.io/leaf2334/default-image.jpg?updatedAt=1712149077447"
}

async function download() {
    let email = document.getElementById("email");
    let body = {
        "email": email.value,
        "video_url": url
    }
    console.log(body)
    let headers = {
        "X-Parse-Application-Id": "ImRyRNJEuhC1dpiZ3BLcbHkc1mjIxNGqQjJXz8Wz",
        "X-Parse-REST-API-Key": "HVGuFpri6LhwilA59RqxLOem40lmACZzjyz4O41F",
        "Content-Type": "application/json"
    }
    submit.setAttribute("disabled","disabled")
    axios.post(baseAPI, body, {headers}).catch( (error) => setFeedback(JSON.stringify(error)))
    submit.removeAttribute("disabled")
    window.open(url, "_blank");
    /* const a = document.createElement('a')
    a.href = url
    a.target = "_blank"
    a.download = url.split('/').pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a) */
}

let email = document.getElementById("email");
email.addEventListener("input", validate);

function validate(e) {
            
    var valid = false;
    valid = checkEmail(e.target.value);
    
    if(valid) {
       submit.removeAttribute("disabled")
    }	
}
function checkEmail(obj) {
    var result = true;
    
    var email_regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,3})+$/;
    result = email_regex.test(obj);
    
    if(!result) {
        return false;
    }
    
    return result;	
}

function setFeedback(message) {
    document.getElementById("feedback").innerHTML = message
}